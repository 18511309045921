<template>
  <div>
    <h1>光照疗效指标测验报告</h1>
    <h2>重庆市精神卫生中心</h2>
    <table>
      <tr>
        <td valign="center" rowspan="1" colspan="4" style="text-align: center">
          姓名：{{ this.update.cname }}
        </td>
        <td valign="center" rowspan="1" colspan="4" style="text-align: center">
          患者ID：{{ this.update.cid }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="4" style="text-align: center">
          科室：{{ this.update.section }}
        </td>
        <td valign="center" rowspan="1" colspan="4" style="text-align: center">
          指导医生：{{ this.update.doctor }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="10" style="text-align: center">
          报告时间：{{ this.update.dateTime }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          序号
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          项目
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          评分
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          参考诊断
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          1
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          抑郁水平
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.HAMD }}
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.HAMDText }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          2
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          焦虑水平
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.BAI }}
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.BAIText }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          3
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          睡眠质量
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.Sleep_quality }}
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.Sleep_qualityText }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          4
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          睡眠障碍
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.Sleep_disorders }}
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.Sleep_disordersText }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          5
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          睡眠觉醒类型
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.MEQ }}
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.MEQText }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          6
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          睡眠综合评估
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.Sleep }}
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.Sleep_text }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { Message } from "element-ui";
import api from "../../http/api";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("patient");
export default {
  data() {
    return {
      update: {},
    };
  },
  created() {
    this.getOneReport();
  },
  updated() {
    // console.log("MEQ", this.MEQ);
  },
  mounted() {
    // console.log(11111111111, this.$route.params);
    // console.log("MEQ", this.MEQ);
  },

  computed: {
    cid() {
      return this.$route.params.cid;
    },
    id() {
      return this.$route.params.id;
    },
    // 睡眠觉醒类型
    MEQ() {
      if (this.update.data !== "暂无") {
        let data = this.update.data;
        var arr = data.split(",");
        let meq = arr.map(Number)[2];
        return meq;
      } else {
        return "/";
      }
    },
    // 睡眠综合评分
    Sleep() {
      if (this.update.etId !== "E6") {
        return "你还没有做该项评估";
      } else {
        return this.update.score;
      }
    },
    Sleep_text() {
      if (this.update.etId !== "E6") {
        return "你还没有做该项评估";
      } else {
        return this.update.solution;
      }
    },
    // 焦虑评估
    BAI() {
      if (this.update.data !== "暂无") {
        let data = this.update.data;
        var arr = data.split(",");
        let bai = arr.map(Number)[3];
        return bai;
      } else {
        return "/";
      }
    },
    // 抑郁评估
    HAMD() {
      if (this.update.data !== "暂无") {
        let data = this.update.data;
        var arr = data.split(",");
        let hamd = arr.map(Number)[4];
        return hamd;
      } else {
        return "/";
      }
    },
    // 睡眠质量
    Sleep_quality() {
      if (this.update.data !== "暂无") {
        let data = this.update.data;
        var arr = data.split(",");
        let a = arr.map(Number)[0];
        return a;
      } else {
        return "/";
      }
    },
    // 睡眠障碍
    Sleep_disorders() {
      if (this.update.data !== "暂无") {
        let data = this.update.data;
        var arr = data.split(",");
        let a = arr.map(Number)[1];
        return a;
      } else {
        return "/";
      }
    },
    // 睡眠觉醒类型诊断参考
    MEQText() {
      if (this.MEQ < 23) {
        return "分数不正常，请重新评估";
      }
      if (this.MEQ >= 23 && this.MEQ <= 26) {
        return "绝对 “夜晚”型";
      }
      if (this.MEQ >= 27 && this.MEQ <= 30) {
        return "绝对 “夜晚”型";
      }
      if (this.MEQ >= 31 && this.MEQ <= 34) {
        return "中度 “夜晚”型";
      }
      if (this.MEQ >= 35 && this.MEQ <= 38) {
        return "中度 “夜晚”型";
      }
      if (this.MEQ >= 39 && this.MEQ <= 41) {
        return "中度 “夜晚”型";
      }
      if (this.MEQ >= 42 && this.MEQ <= 45) {
        return "中间型";
      }
      if (this.MEQ >= 46 && this.MEQ <= 49) {
        return "中间型";
      }
      if (this.MEQ >= 50 && this.MEQ <= 53) {
        return "中间型";
      }
      if (this.MEQ >= 54 && this.MEQ <= 57) {
        return "中间型";
      }
      if (this.MEQ >= 58 && this.MEQ <= 61) {
        return "中度 “清晨”型";
      }
      if (this.MEQ >= 62 && this.MEQ <= 65) {
        return "中度 “清晨”型";
      }
      if (this.MEQ >= 66 && this.MEQ <= 69) {
        return "中度 “清晨”型";
      }
      if (this.MEQ >= 70 && this.MEQ <= 72) {
        return "绝对 “清晨”型";
      }
      if (this.MEQ >= 72 && this.MEQ <= 86) {
        return "中度 “清晨”型";
      }
    },
    BAIText() {
      if (this.BAI < 15) {
        return "无焦虑";
      }
      if (this.BAI >= 15 && this.BAI <= 25) {
        return "轻度焦虑";
      }
      if (this.BAI > 26 && this.BAI <= 35) {
        return "中度焦虑";
      }
      if (this.BAI >= 36) {
        return "重度焦虑";
      }
    },
    HAMDText() {
      if (this.HAMD < 8) {
        return "正常";
      }
      if (this.HAMD >= 8 && this.HAMD <= 20) {
        return "可能有抑郁症";
      }
      if (this.HAMD > 20 && this.HAMD <= 35) {
        return "肯定有抑郁症";
      }
      if (this.HAMD > 35) {
        return "严重抑郁症";
      }
    },
    Sleep_qualityText() {
      if (this.Sleep_quality == 0) {
        return "很好";
      }
      if (this.Sleep_quality == 1) {
        return "较好";
      }
      if (this.Sleep_quality == 2) {
        return "较差";
      }
      if (this.Sleep_quality == 3) {
        return "很差";
      }
    },
    Sleep_disordersText() {
      if (this.Sleep_disorders == 0) {
        return "无";
      }
      if (this.Sleep_disorders == 1) {
        return "轻度";
      }
      if (this.Sleep_disorders == 2) {
        return "中度";
      }
      if (this.Sleep_disorders == 3) {
        return "重度";
      }
    },
  },
  methods: {
    async getOneReport(cid, id) {
      this.dialogVisible2 = true;
      const data = await api.patient.lookRportOne(this.cid, this.id);
      if (data.status == 200) {
        this.update = data.data.data[0];
        // this.name = data.data.data[0].cname;
        // console.log("姓名", this.name);
        // console.log("获取某一个数据：", this.update);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
table {
  border: 1px solid black;
  border-collapse: collapse;
  margin: 0 auto;
  width: 800px;
}
table td,
th {
  border: 1px black solid;
  background-color: white;
  height: 40px;
  width: 100px;
}
</style>
